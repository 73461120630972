<template>
  <div class="sendEmailModal">
    <div class="modal fade" id="enquiry-view" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header d-flex justify-space-between align-items-center"
          >
            <h5 class="mb-0 text-primary">
              {{ enquiry.name }} enquired on
              {{ enquiry.created_at | formatDate }}
            </h5>
          </div>

          <!-- Modal Body -->
          <div
            class="modal-body bg-white"
            style="max-height: 75vh; overflow: scroll"
          >
            <div class="row">
              <div class="col">
                <p class="bg-light p-2">{{ enquiry.enquiry }}</p>
              </div>
            </div>
          </div>

          <!-- Modal Actions -->
          <div class="modal-footer py-2 bg-white">
            <button
              type="button"
              class="btn btn-outline-secondary"
              ref="close"
              data-bs-dismiss="modal" data-dismiss="modal"
              aria-label="Close"
            >
              Close
            </button>
            <button class="btn btn-primary ms-auto" @click="convertToClient">
              Convert to Client
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["enquiry"],
  data() {
    return {};
  },

  methods: {
    markEnquiryRead() {
      if (this.enquiry.status == "new") {
        this.enquiry.status = "read";
        this.$axios
          .put(
            process.env.VUE_APP_API_URL + "/enquiries/api/" + this.enquiry.id,
            this.enquiry
          )
          .then(({ data }) => {
            // this.$EventBus.$emit("alert", data);
          });
      }
    },
    convertToClient() {
      this.$emit("convert", this.enquiry);
      this.$refs.close.click();
    },
  },
  mounted() {
    $("#enquiry-view").modal("show");
    this.markEnquiryRead();
    $("#enquiry-view").on("hide.bs.modal", (e) => {
      this.$emit("close");
    });
  },
  components: {},
  filters: {
    formatDate(date) {
      return moment(date).format("LLL");
    },
  },
};
</script>

<template>
  <div class="container-fluid">
    <h4 class="text-primary fw-bold mb-0">Enquiries</h4>
    <div class="row">
      <div class="col">
        <div class="card border-0 shadow-none">
          <div class="card-body">
            <div class="row mb-4">
              <div class="col">
                <p class="alert alert-primary">
                  Enquiries may come from the Enquiry Form Web Snippet available
                  for use
                  <a href="/settings/snippets/appointment-booker">here</a>, or
                  from your Swandoola public profile (if enabled)
                </p>
              </div>
            </div>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Status</th>
                  <th scope="col">Source</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="e in enquiries" :key="e.id" class="cursor-pointer">
                  <td @click="selectedEnquiry = e">{{ e.name }}</td>
                  <td @click="selectedEnquiry = e">{{ e.email }}</td>
                  <td @click="selectedEnquiry = e">{{ e.phone || "-" }}</td>
                  <td @click="selectedEnquiry = e" class="text-capitalize">
                    {{ e.status }}
                  </td>
                  <td @click="selectedEnquiry = e" class="text-lowercase">
                    {{ e.source ? e.source : "-" }}
                  </td>
                  <td>
                    <button
                      class="btn btn-xs btn-primary me-2"
                      @click="selectedEnquiry = e"
                    >
                      <i class="far fa-eye me-2"></i>View Detail
                    </button>

                    <button
                      class="btn btn-xs btn-light me-2"
                      @click="convertToClient(e)"
                      v-if="!e.client_id"
                    >
                      <i class="far fa-user me-2"></i>Convert to Client
                    </button>

                    <button
                      class="btn btn-xs btn-light me-2"
                      @click="viewClient(e)"
                      v-else
                    >
                      <i class="far fa-user me-2"></i>View Client
                    </button>

                    <button
                      class="btn btn-xs btn-outline-danger"
                      @click="deleteEnquiry(e)"
                    >
                      <i class="far fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col my-auto">
                    <button
                      class="btn btn-sm btn-light"
                      @click="fetchEnquiries(pagination.first_page_url)"
                      :disabled="!pagination.first_page_url"
                    >
                      First Page
                    </button>

                    <button
                      class="btn btn-sm btn-light ms-3"
                      @click="fetchEnquiries(pagination.prev_page_url)"
                      :disabled="!pagination.prev_page_url"
                    >
                      Previous
                    </button>
                  </div>
                  <div class="col my-auto text-center">
                    <p class="mb-0">
                      Page {{ pagination.current_page }} of
                      {{ pagination.last_page }}
                    </p>
                  </div>
                  <div class="col my-auto text-end">
                    <button
                      class="btn btn-sm btn-light cursor-pointer me-3"
                      @click="fetchEnquiries(pagination.next_page_url)"
                      :disabled="!pagination.next_page_url"
                    >
                      Next
                    </button>

                    <button
                      class="btn btn-sm btn-light cursor-pointer"
                      @click="fetchEnquiries(pagination.last_page_url)"
                      :disabled="!pagination.last_page_url"
                    >
                      Last Page
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <enquiry-view
      v-if="selectedEnquiry"
      :enquiry="selectedEnquiry"
      @convert="convertToClient"
      @close="selectedEnquiry = null"
    ></enquiry-view>
  </div>
</template>

<script>
import EnquiryView from "./ViewModal";

export default {
  data() {
    return {
      enquiries: [],
      pagination: {},
      selectedEnquiry: null,
    };
  },
  methods: {
    viewClient(e) {
      this.$router.push("/clients/" + e.client_id);
    },
    convertToClient(enquiry) {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/enquiries/api/" +
            enquiry.id +
            "/convert-to-client"
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
        });
    },
    fetchEnquiries(paginationUrl) {
      const url = paginationUrl
        ? paginationUrl
        : process.env.VUE_APP_API_URL + "/enquiries/api";

      this.$axios.get(url).then(({ data }) => {
        this.pagination = data;
        this.enquiries = data.data;
      });
    },
    deleteEnquiry(enquiry) {
      var confirmed = confirm(
        "Are you sure you want to delete this enquiry? You cannot reverse this action."
      );
      if (confirmed) {
        this.$axios
          .delete(process.env.VUE_APP_API_URL + "/enquiries/api/" + enquiry.id)
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.fetchEnquiries();
          });
      }
    },
  },
  mounted() {
    this.fetchEnquiries();
  },
  components: {
    EnquiryView,
  },
};
</script>

<style>
</style>
